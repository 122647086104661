<!--
File: Worklist.vue
Description: component of the TitleList.vue.
-->
<template>
  <md-card style="height: 80%;">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout" style="align-items: center; ">
      <div v-show="step === 'third'" class="md-layout-item md-small-size-100 md-size-20">
          <md-button class="md-primary md-simple" @click='buttonBackClicked'>
            <md-icon>arrow_back</md-icon>{{ $t('Back to the list') }}
          </md-button>
        </div>
        <div class="md-layout-item md-small-size-20 md-size-15">
          <BaseDropdown :label="$t('Saved worklists')" v-model="selectedWorklist" :items="savedWorksList"
            :displayField="'year'" :valueField="'work_list_id'" @input="reloadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" data-vv-name="selectedRegion"
            :initial_value="selectedRegion" @input="reloadTreatmentsList" />
        </div>
        <div v-show="step === 'second'" class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown id='planFilter' :label="$t('label.plans')" v-model="selectedPlan" :items="plansList"
            :displayField="'name'" :valueField="'id'" @input="reloadTreatmentsList" />
        </div>
        <div v-show="showAdditionalDropdowns" class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('budget.select_currency')" v-model="showCurrency" :items="currencies"
            :displayField="'name'" :valueField="'id'" />
        </div>
        <div v-show="showAdditionalDropdowns" class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown :label="$t('Show as')" v-model="showAmounts" :items="amounts" :displayField="'name'"
            :valueField="'id'" />
        </div>
        <div v-show="showAdditionalDropdowns" class="md-layout-item btn-row md-small-size-100">
          <label v-show="step === 'second' && isPlanApproved" style="color: red;">Plan is approved</label>
          <md-button v-show="step === 'second' && !isPlanApproved" class="md-raised md-success" @click="approvePlan()">
            {{ $t('buttons.approve') }}
          </md-button>
          <!--md-button class="md-raised md-success">{{ $t('buttons.print') }}</md-button-->
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div v-show="showSpinner" class="md-layout-item">
        <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
      </div>
      <md-table class='"paginated-table table-striped table-hover' :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" :md-selected-value.sync='selectedItems'
        md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}"
          v-bind="!isPlanApproved ? { 'md-selectable': 'multiple' } : {}">
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <!--md-table-cell :md-label="$t('label.hs_id')" md-sort-by="hs_id">
            {{ item.hs_id }}
          </md-table-cell-->
          <md-table-cell :md-label="$t('inventory.start_distance_m')" md-sort-by="start_distance_m" md-numeric>
            {{ item.start_m | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('inventory.end_distance_m')" md-sort-by="end_distance_m" md-numeric>
            {{ item.end_m | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.units')" md-sort-by="unit_description">
            {{ item.unit_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.number_units')" md-sort-by="units" md-numeric>
            {{ item.units | numFormat(2) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('treatment.treatment_description')" md-sort-by="treatment_type_description">
            {{ item.treatment_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.cost')" md-sort-by="cost" md-numeric>
            {{ item.cost / showAmounts | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions style="display: flex; justify-content: space-between; align-items: center;">
      <div v-show="selectedItems.length > 0">
        <div style="display: flex; justify-content: space-between; ">
          <span>{{ $t('budget.selected') }} {{ selectedItems.length }} {{ $t('item(s)') }}</span>
          <div v-show="step === 'first'">
            <div style="display: flex; align-items: center; margin-left: auto; margin-top: 2px;">
              <span>{{ $t('Assign to the plan:') }}</span>
              <div class="md-layout-item md-small-size-100 md-size-60">
                <BaseDropdown id='planAssign' :label="$t('label.select_plan')" v-model="selectedPlanForAssignment"
                  :items="planListForAssignment" :displayField="'name'" :valueField="'id'" @input="onPlanSelected()" />
              </div>
            </div>
          </div>
          <div v-show="step === 'second'">
            <md-button class="md-raised md-danger" @click="unassignFromPlan()">
              {{ $t('Unassign from plan') }}
            </md-button>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-guter md-small-size-100 md-size-40" style="margin-left: auto; text-align: left;">
        <span>{{ $t('Totals:') }}<br>
          Records: {{ total | numFormat(0) }},
          Length: {{ treatmentsSummary.totalLength | numFormat(0) }},
          Sum: {{ treatmentsSummary.totalSum / showAmounts | numFormat }}
        </span>
      </div>
    </md-card-actions>
  </md-card>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/customSortMixin'
  import { numFormat } from "@/store/refdata/format_helpers"

  export default {
    mixins: [customSortMixin],
    name: 'work-list-form',
    data() {
      return {
        showSpinner: true,
        selectedWorklist: null,
        selectedRegion: null,
        selectedPlanForAssignment: null,
        selectedPlan: null,
        selectedBudget: null,
        selectedItems: [],
        showAmounts: 1000000,
        amounts: [
          { id: 1, name: this.$t("soms") },
          { id: 1000, name: this.$t("Thousands") },
          { id: 1000000, name: this.$t("Millions") }
        ],
        showCurrency: 'kgs',
        currencies: [
          { id: 'kgs', name: 'KGS' },
          { id: 'usd', name: 'USD' }
        ],

        currentSort: 'section_description',
        currentSortOrder: 'asc',
      }
    },

    props: {
      step: { default: 'first', type: String },
    },

    components: {
      RegionsDropdown,
      BaseDropdown
    },

    mounted() {
      this.toggleSpinner(true)

      // Reset the lists in the store if they were filled previouly
      this.$store.dispatch('RESET_WORK_LISTS')
      this.$store.dispatch('RESET_TREATMENTS')

      this.$store.dispatch('LOAD_BUDGET_LIST').then(() => {
        //res.data.splice(0, 0, { section_id: 0, section_description: '' })
        //console.log(this.plansList)
      })
      this.toggleSpinner(false)
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },
      buttonBackClicked() {
        this.$emit('back')
      },
      reloadTreatmentsList() {
        this.toggleSpinner(true)

        let returnZeroRows = !this.selectedRegion || !this.selectedWorklist
        //if (this.step === 'first' && !this.selectedWorklist) returnZeroRows = true
        if (this.step === 'second' && !this.selectedPlan) returnZeroRows = true

        if (returnZeroRows) {
          this.$store.dispatch('RESET_TREATMENTS')
          this.toggleSpinner(false)
          return
        }
console.log('reloadTreatmentsList', this.selectedRegion , this.selectedWorklist, this.selectedPlan )
        this.selectedItems = []
        const params = {
          work_list_id: this.selectedWorklist,
          region_id: this.selectedRegion,
          is_plan_assigned: this.step === 'first' ? 0 : 1,
          //is_budget_assigned: this.step === 'first' || this.step === 'second' ? 0 : 1,
          is_approved: 0,
          plan_id: this.step === 'second' ? this.selectedPlan : null,
          budget_id: this.step === 'third' ? this.selectedBudget : null,
        }
        this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
console.log('data loaded', this.treatmentsList)
          this.toggleSpinner(false)
        })
      },
      reloadPlanList() {
        if (!this.selectedRegion || !this.selectedYear) {
          this.$store.commit('SET_PLAN_LIST', [])
          return
        }
        const params = {
          region_id: this.selectedRegion,
          year: this.selectedYear
        }
        this.$store.dispatch('LOAD_PLAN_LIST', params).then(() => {
          this.selectedPlanForAssignment = null
        })
      },
      async onPlanSelected() {
        if (this.selectedPlanForAssignment == null) {
          // To catch if We cleared it when jumping between tabs
          return
        }
        this.toggleSpinner(true)
        while (true) {  // Looping until user enters unique plan name
          if (this.selectedPlanForAssignment == 0) {
            const alertOptions = {
              title: this.$t('plans.create_plan'),
              input: "text",
              showCancelButton: true,
              inputPlaceholder: this.$t('label.enter_name')
            }
            const { value } = await Swal.fire(alertOptions)
            if (!value || value === '') { //User cancelled input
              console.log('cancelled')
              this.selectedPlanForAssignment = null
              this.toggleSpinner(false)
              return
            }

            this.selectedPlanForAssignment = await this.createPlan(value)
            if (this.selectedPlanForAssignment < 0) { // error
              return
            } else if (this.selectedPlanForAssignment == 0) { // name exists
              continue
            } else {
              break
            }
          } else {  // the name is unique from beginning
            break
          }
        }
        this.assignPlan()
        this.reloadPlanList()
      },
      async createPlan(planName) {
        const plan = {
          name: planName,
          fk_region: this.selectedRegion,
          year: this.selectedYear
        }
        const newPlanId = await this.$store.dispatch('ADD_PLAN', plan)
        if (typeof newPlanId === 'number') {
          return newPlanId
        } else {
          const res = newPlanId === 'exists' ? 0 : -1
          const alert = {
            icon: 'error',
            title: res == 0 ? this.$t('messages.name_exists') : this.$t('messages.error'),
            text: res == 0 ? this.$t('messages.choose_another_name') : this.$t(`plans.plan_was_not_saved`, { name: planName }),
          }
          await Swal.fire(alert)
          return res
        }
      },
      handleAssignment(id, successMessage) {
        this.toggleSpinner(true)

        let items = this.selectedItems.map(el => el.treatment_id)
        const params = {
          'plan_id': id,
          treatment_id: items
        }
console.log('params', params)
        this.$store.dispatch('ASSIGN_PLAN', params).then(() => {
          this.$emit('itemAssigned', 'plan', id)
          Swal.fire(`${items.length} works were ${successMessage} to the plan`, '', 'success')
          this.reloadTreatmentsList()
          this.toggleSpinner(false)
        })
      },

      assignPlan() {
        this.handleAssignment(this.selectedPlanForAssignment, 'assigned')
      },

      unassignFromPlan() {
        this.handleAssignment(-1, 'unassigned')
      },

      approvePlan() {
        console.log('approvePlan')
        this.toggleSpinner(true)

        this.$store.dispatch('APPROVE_PLAN', this.selectedPlan).then(() => {
          this.reloadPlanList()
          this.$emit('planApproved', this.selectedPlan)
          Swal.fire(`Plan was approved`, '', 'success')
          //this.reloadTreatmentsList()

          this.toggleSpinner(false)
        })
      },
      handleSelectionChange(value, type) {
        this.selectedItems = []
        this.reloadPlanList()
        this.reloadTreatmentsList()
      },
    },

    computed: {
      ...mapState({
        savedWorksList: (state) => state.TitleList.worksLists,
        treatmentsList: (state) => state.TitleList.treatmentsList,
        plansList: (state) => state.Plans.list
      }),
      ...mapGetters(['treatmentsSummary', 'treatmentsByPlanId', 'plansNotApproved', 'planApproved', 'budgetsByRegion']),
      selectedYear() {
        return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist).year)
      },
      budgetList() {
        return this.budgetsByRegion(this.selectedRegion)
      },
      tableData() {
        const res = /*(this.step === 'second') && !this.selectedPlanForAssignment ? this.treatmentsList.filter((item) => {
                                                  return item.fk_plan === this.selectedPlanForAssignment
                                                }) : */this.treatmentsList

        return res
      },
      total() {
        return this.treatmentsSummary.totalRecords
      },
      showAdditionalDropdowns() {
        return this.total > 0
      },
      /*summaryInfo() {
        return this.$store.getters.treatmentsSummary
      },
      treatmentsByPlanId() {
        return this.$store.getters.treatmentsByPlanId(this.selectedPlan)
      },
      plansNotApproved() {
        return this.$store.getters.plansNotApproved()
      },*/
      planListForAssignment() {
        const res = this.plansNotApproved(this.selectedRegion, this.selectedYear)
        res.unshift({ id: 0, name: this.$t('plans.create_plan') })
        return res
      },
      isPlanApproved() {
        return this.planApproved(this.selectedPlan)
      },
      footerTable() {
        return [
          /*
          this.$t('label.total'),
          `${this.$t('budget.works_count')}: ${numFormat(this.treatmentsSummary.totalRecords, 0)}`,
          `${this.$t('road_network.length_km')}: ${numFormat(this.treatmentsSummary.totalLength / 1000, 3)}`,
          `${this.$t('budget.total_cost')}: ${numFormat(this.treatmentsSummary.totalSum / this.showAmounts, 2)}`,
          */
        ]
      }
    },
    watch: {
      step(value) {
        this.selectedPlan = null
        this.selectedPlanForAssignment = null
        this.selectedItems = []
        this.reloadTreatmentsList()
      },
      selectedWorklist(newVal) {
        this.handleSelectionChange(newVal, 'worklist')
      },
      selectedRegion(newVal) {
        this.handleSelectionChange(newVal, 'region')
      },
      selectedPlan(newVal) {
        this.handleSelectionChange(newVal, 'plan')
      }
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 13px !important;
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>