<!--
File: BudgetApprovals.vue
Description: show list of budgets for approving in the DB.
-->
<template>
  <div>
    <md-card style="height: 80%;">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout" style="align-items: center; justify-content: flex-start; width: auto;">
          <div class="md-layout-item md-small-size-20 md-size-15">
            <BaseDropdown :label="$t('budget.year')" v-model="selectedWorklist" :items="savedWorksList"
              :displayField="'year'" :valueField="'work_list_id'" @input="reloadBudgetList" :is_required="false" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
              @input="reloadBudgetList" :is_required="false" />
          </div>
          <div v-show="total > 0" class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown :label="$t('budget.select_currency')" v-model="showCurrency" :items="currencies"
              :displayField="'name'" :valueField="'id'" />
          </div>
          <div v-show="total > 0" class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown :label="$t('Show as')" v-model="showAmounts" :items="amounts" :displayField="'name'"
              :valueField="'id'" />
          </div>
          <div v-show="selectedYear && selectedRegion" class="md-layout-item btn-row md-small-size-100">
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div v-show="showSpinner" class="md-layout-item">
          <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
        </div>
        <md-table class='"paginated-table table-striped table-hover' :value="budgetList" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('budget.category')" md-sort-by="work_category">
              {{ item.work_category }}
            </md-table-cell>

            <md-table-cell :md-label="$t('budget.amount') + ', ' + showCurrency" md-sort-by="amount_kgs" :md-numeric=true>
              {{ (showCurrency === "kgs" ? item.amount_kgs : item.amount_usd) / showAmounts | numFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.works_count')" :md-numeric=true md-sort-by="treatment_count">
              {{ item.treatment_count }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div class="cell-actions">
                <md-button v-show="item.treatment_count > 0" class="md-just-icon md-success md-simple"
                  native-type="submit" @click.native.prevent="showAssignedTreatments(item)">
                  <md-icon>preview</md-icon>
                </md-button>

                <template v-if="item.approved == false">
                  <md-button class="md-success md-raised md-sm " @click.native="toggleApprove(item)">
                    {{ $t('buttons.approve') }}
                  </md-button>
                </template>
                <template v-else>
                  <md-button class="md-raised md-sm md-primary" @click.native="toggleApprove(item)">
                    {{ $t('buttons.revoke') }}
                  </md-button>
                </template>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for=" item  in  footerTable " :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="showAssignedForm" :md-click-outside-to-close="false">
      <md-dialog-title>{{ $t('Treatments with assigned budget') }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='toggleAssignedForm(false)'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <AssignedBudgetsForm :budget="selectedBudget" :workList="selectedWorklist" @close="toggleAssignedForm(false)" />
    </md-dialog>
</div>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import AssignedBudgetsForm from './AssignedBudgetsForm.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'budget-list-form',
    data() {
      return {
        showSpinner: true,
        selectedWorklist: null,
        selectedRegion: null,
        selectedCategory: null,
        showEditForm: null,
        editFormTitle: '',

        selectedBudget: null,
        showAmounts: 1000000,
        amounts: [
          { id: 1, name: this.$t("as is") },
          { id: 1000, name: this.$t("in thousands") },
          { id: 1000000, name: this.$t("in millions") }
        ],
        showCurrency: 'kgs',
        currencies: [
          { id: 'kgs', name: 'KGS' },
          { id: 'usd', name: 'USD' }
        ],

        currentSort: 'work_category',
        currentSortOrder: 'asc',
        showAssignedForm: false,
     }
    },

    props: {
      step: { default: 'first', type: String },
    },

    components: {
      RegionsDropdown,
      BaseDropdown,
      AssignedBudgetsForm
    },

    mounted() {
      this.toggleSpinner(true)
      this.$store.dispatch('RESET_TREATMENTS')
      this.$store.dispatch('GET_WORK_CATEGORIES_LIST', true)
      this.reloadBudgetList()
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },
      toggleEditForm(state) {
        this.showEditForm = state
      },
      toggleAssignedForm(state) {
      this.showAssignedForm = state
    },
      reloadBudgetList() {
        this.toggleSpinner(true)
        this.$store.dispatch('LOAD_BUDGET_LIST').then(() => {
          this.toggleSpinner(false)
        })
      },
      showAssignedTreatments(item) {
      this.selectedBudget = item
      this.toggleAssignedForm(true)
    },
      async toggleApprove(item) {
        //let count = 0
        //let errCount = 0
        let err = false
        //this.budgetList.forEach(budget => {
        const theBudget = {
          year: item.year,
          fk_region: item.fk_region,
          fk_work_category: item.fk_work_category,
          amount_kgs: item.amount_kgs,
          amount_usd: item.amount_usd,
          version: item.version,
          approved: Number(!item.approved),
          updated_at: new Date()
        }
        const id = item.budget_id
        //delete item.budget_id

        //this.$store.dispatch('TOGGLE_BUDGET_APPROVAL', budget_id).then(() => { })
        await this.$store.dispatch('UPD_BUDGET', { id: id, theBudget: theBudget }).then(
          () => { /*count++*/ },
          (err) => { err = true /*errCount++ */ })
        //})
        const alert = {
          //icon: !errCount ? 'success' : 'warning',
          icon: !err ? 'success' : 'warning',
          text: this.$t(`${!item.approved ? 'label.was_approved' : 'label.was_revoked'}`, { cat: `${item.work_category}` }),
          footer: ''//errCount ? `${errCount} errors` : ''
        }
        Swal.fire(alert)
        this.reloadBudgetList()
      },
    },

    computed: {
      ...mapState({
        savedWorksList: (state) => state.TitleList.worksLists,
        workCategoriesList: (state) => state.Dropdowns.work_categories
        //budgetList: (state) => state.Budgets.list
      }),
      ...mapGetters([
        'amIAdmin', 'budgetsByCategory', 'budgetApproved', 'budgetSummary',
        'plansByRegion', 'budgetsByRegionAndYear'
      ]),
      budgetList() {
        //return this.$store.state.Budgets.list
        return this.budgetsByRegionAndYear(this.selectedRegion, this.selectedYear)
      },
      total() {
        return this.budgetList.length
      },
      summaryInfo() {
        return this.budgetSummary(this.selectedRegion, this.selectedYear)
      },
      approvedBudget() {
        return this.summaryInfo.totalApproved
      },
      selectedYear() {
        return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist).year)
      },
      footerTable() {
        return [
          this.$t('label.total'),
          `${this.$t('label.categories')}: ${numFormat(this.summaryInfo.totalRecords, 0)}`,
          `${this.$t('budget.amount')}:: ${numFormat((this.showCurrency === "kgs"
            ? this.summaryInfo.totalKGS : this.summaryInfo.totalUSD) / this.showAmounts, 2)}`,
          `${this.$t('budget.approved')}: ${numFormat(this.approvedBudget / this.showAmounts, 2)}`
        ]
      },
    },

    watch: {
      step(value) {
        //this.reloadTreatmentsList()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cell-actions {
  display: flex;
  justify-content: flex-end;
  //flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>