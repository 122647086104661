<!--
File: Budgets.vue
Description: show list of budgets entered in the DB.
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout" style="align-items: center; justify-content: flex-start; width: auto;">
          <div class="md-layout-item md-small-size-20 md-size-10">
            <BaseDropdown :label="$t('budget.year')" v-model="selectedWorklist" :items="savedWorksList"
              :displayField="'year'" :valueField="'work_list_id'" @input="reloadBudgetList" :is_required="false" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
              @input="reloadBudgetList" :is_required="false" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown :label="$t('translate.category')" v-model="selectedCategory" :items="workCategoriesList"
              :displayField="'description'" :valueField="'work_category_id'" />
          </div>
          <div v-show="total > 0" class="md-layout-item md-small-size-100 md-size-10">
            <BaseDropdown :label="$t('budget.select_currency')" v-model="showCurrency" :items="currencies"
              :displayField="'name'" :valueField="'id'" />
          </div>
          <div v-show="total > 0" class="md-layout-item md-small-size-100 md-size-10">
            <BaseDropdown :label="$t('Show as')" v-model="showAmounts" :items="amounts" :displayField="'name'"
              :valueField="'id'" />
          </div>

          <div v-show="selectedYear && selectedRegion" class="md-layout-item btn-row md-small-size-50">
            <md-button class="md-success" @click="showAssignedWorks">
              {{ $t('buttons.show_works') }}
            </md-button>
            <md-button v-show="approvedBudget == false" class="md-raised md-success" @click="addBudget()">
              {{ $t('buttons.add') }}
            </md-button>
            <md-button v-show="approvedBudget == false" class="md-success" @click="populate">
              {{ $t('buttons.populate') }}
            </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div v-show="showSpinner" class="md-layout-item">
          <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
        </div>
        <md-table class='"paginated-table table-striped table-hover' :value="budgetList" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('budget.category')" md-sort-by="work_category">
              {{ item.work_category }}
            </md-table-cell>

            <md-table-cell :md-label="$t('budget.amount') + ', ' + showCurrency" md-sort-by="amount_kgs" :md-numeric=true>
              {{ (showCurrency === "kgs" ? item.amount_kgs : item.amount_usd) / showAmounts | numFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.works_count')" :md-numeric=true md-sort-by="treatment_count">
              {{ item.treatment_count }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div>
                <md-button v-if="item.approved == false" class="md-just-icon md-default md-simple"
                  :title="$t('buttons.edit')" @click.native="editBudget(item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button v-show="item.treatment_count > 0" class="md-just-icon md-success md-simple"
                  :title="$t('buttons.show_works')" native-type="submit"
                  @click.native.prevent="showAssignedTreatments(item)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button v-if="amIAdmin && item.approved == false" class="md-just-icon md-danger md-simple"
                  :title="$t('buttons.delete')" @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="showEditForm" :md-click-outside-to-close="false">
      <md-dialog-title>{{ editFormTitle }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='toggleEditForm(false)'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <BudgetEditForm :budget="selectedBudget" @close="toggleEditForm(false)" @saved="onSave" />
    </md-dialog>

    <md-dialog :md-active.sync="showAssignedForm" :md-click-outside-to-close="false">
      <md-dialog-title>{{ $t('Treatments with assigned budget') }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='toggleAssignedForm(false)'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <AssignedBudgetsForm :budget="selectedBudget" :workList="selectedWorklist" @close="toggleAssignedForm(false)" />
    </md-dialog>
  </div>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import BudgetEditForm from './BudgetEditForm.vue'
  import AssignedBudgetsForm from './AssignedBudgetsForm.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'budget-list-form',
    data() {
      return {
        showSpinner: true,
        selectedWorklist: null,
        selectedRegion: null,
        selectedCategory: null,
        editFormTitle: '',
        showEditForm: null,
        showAssignedForm: false,

        selectedBudget: null,
        showAmounts: 1000000,
        amounts: [
          { id: 1, name: this.$t("as is") },
          { id: 1000, name: this.$t("in thousands") },
          { id: 1000000, name: this.$t("in millions") }
        ],
        showCurrency: 'kgs',
        currencies: [
          { id: 'kgs', name: 'KGS' },
          { id: 'usd', name: 'USD' }
        ],

        currentSort: 'work_category',
        currentSortOrder: 'asc',
      }
    },

    props: {
      step: { default: 'first', type: String },
    },

    components: {
      RegionsDropdown,
      BaseDropdown,
      BudgetEditForm,
      AssignedBudgetsForm
    },

    mounted() {
      this.toggleSpinner(true)
      this.$store.dispatch('RESET_TREATMENTS')
      this.$store.dispatch('GET_WORK_CATEGORIES_LIST', true)
      this.reloadBudgetList()
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },
      toggleEditForm(state) {
        this.showEditForm = state
      },
      toggleAssignedForm(state) {
        this.showAssignedForm = state
      },
      reloadBudgetList() {
        this.toggleSpinner(true)
        this.$store.dispatch('LOAD_BUDGET_LIST').then(() => {
          this.toggleSpinner(false)
        })
      },
      loadTreatmentsList() {
        this.toggleSpinner(true)

        if (!this.selectedRegion || !this.selectedWorklist) {
          //this.$store.state.TitleList.treatmentsList = []
          this.$store.commit('SET_GENERATED_TREATMENTS', [])
          this.toggleSpinner(false)
          console.log('not loadded')
          return
        }

        this.selectedItems = []
        const params = {
          work_list_id: this.selectedWorklist,
          region_id: this.selectedRegion,
          is_plan_assigned: 1,
          is_budget_assigned: 0,
          is_approved: 0,
        }
        this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
          this.toggleSpinner(false)
          console.log('treatmentsList', this.treatmentsList)

        })
      },
      editBudget(item) {
        this.editFormTitle = this.$t('route.budget_upd')
        this.selectedBudget = item
        this.toggleEditForm(true)
      },
      addBudget() {
        this.editFormTitle = this.$t('route.budget_add')
        this.selectedBudget = {
          year: this.selectedYear,
          fk_region: this.selectedRegion,
          fk_work_category: this.selectedCategory,
        }
        this.toggleEditForm(true)
      },
      onSave() {
        this.reloadBudgetList()
        this.toggleEditForm(false)
      },
      showAssignedWorks() {
        this.$router.push({ name: 'budget_show_populated', params: { 
          workList: this.selectedWorklist, region: this.selectedRegion, year: this.selectedYear 
        }})
      },
      async populate() {
        let params = {
          work_list_id: this.selectedWorklist,
          region: this.selectedRegion,
          year: this.selectedYear
        }
        const res = await this.$store.dispatch('POPULATE_TREATMENTS', params)
        await Swal.fire('Populated', res + ' entries were added.', 'success')
        this.reloadBudgetList()
        if (res > 0) {
          this.showAssignedWorks()
        }
      },
      showAssignedTreatments(item) {
        console.log('showAssignedTreatments: selectedWorklist, budget_id', this.selectedWorklist, item.budget_id)
        //this.$emit('showTreatments')
        this.selectedBudget = item
        this.toggleAssignedForm(true)
      },
    },

    computed: {
      ...mapState({
        savedWorksList: (state) => state.TitleList.worksLists,
        workCategoriesList: (state) => state.Dropdowns.work_categories,
        treatmentsList: (state) => state.TitleList.treatmentsList,
        //budgetList: (state) => state.Budgets.list
        wpList: (state) => state.TitleList.assignedTreatments,
      }),
      ...mapGetters([
        'amIAdmin', 'budgetsByCategory', 'budgetApproved', 'budgetSummary',
        'plansByRegion', 'budgetsByRegionAndYear'
      ]),
      budgetList() {
        //return this.$store.state.Budgets.list
        return this.budgetsByCategory(this.selectedRegion, this.selectedYear, this.selectedCategory)
      },
      total() {
        return this.budgetList.length
      },
      summaryInfo() {
        return this.budgetSummary(this.selectedRegion, this.selectedYear)
      },
      approvedBudget() {
        return this.summaryInfo.approved
      },
      selectedYear() {
        return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist).year)
      },
      footerTable() {
        return [
          this.$t('label.total'),
          `Categories: ${numFormat(this.summaryInfo.totalRecords, 0)}`,
          `Amount: ${numFormat(
            (this.showCurrency === "kgs" ? this.summaryInfo.totalKGS : this.summaryInfo.totalUSD) / this.showAmounts
          )}`,
          // `${this.summaryInfo.approved ? 'Approved' : 'Not Approved'}`
        ]
      },
    },

    watch: {
      step(value) {
        //this.reloadTreatmentsList()
      },
    }
  }
</script>
<style lang="scss">
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  padding: 0px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>