<!--
File: TitleList.vue
Description: form for generating Titlelist with the WorkList component.
-->
<template>
  <div>
    <md-steppers :md-active-step.sync="active" md-linear>
      <md-step id="first" md-label="Worklist selection" md-description="View the treatments and assign them to a plan"
        :md-done.sync="first">
      </md-step>

      <md-step id="second" md-label="Second Step" md-description="Aassign ttreatments to a budget"
        :md-done.sync="second">
      </md-step>

      <md-step id="third" md-label="Third Step" md-description="Budget allocation" :md-done.sync="third">
        <p>Budget allocation screen</p>
        <Budgets :step="active" v-show="!showWorkList" @showTreatments="showTreatmentsWithAssignedBudget" />
        <!--md-button class="md-raised md-success" @click="setDone('third')">Done</md-button-->
      </md-step>

      <md-step id="fourth" md-label="Fourth Step" md-description="Budget approval" :md-done.sync="fourth"
        :md-error="unApproved" :md-editable="fourth_editable">
        <p>Budget approval</p>
        <BudgetApprovals />
      </md-step>

      <md-step id="fifth" md-label="Fifth Step" md-description="Title list" :md-done.sync="fifth"
        :md-error="unApproved">
        <p>Title list printing screen</p>
        <PrintTitleList @itemAssigned="itemAssigned" :step="active"/>
      </md-step>
    </md-steppers>
    <Worklist v-show="showWorkList" @itemAssigned="itemAssigned" @back="backToStep3" style="height: 80%;"
      :step="active" />
  </div>
</template>

<script>
import Worklist from '@/pages/WorkPlan/Worklist'
import PrintTitleList from '@/pages/WorkPlan/PrintTitleList'
import Panels from '@/pages/Dashboard/Components/Panels'
import Budgets from './Budgets.vue'
import BudgetApprovals from './BudgetApprovals.vue'


export default {
  name: 'title-list-creation',
  data: () => ({
    active: 'first',
    first: true,
    second: true,
    third: true,
    fourth: true,
    fifth: true,

    showWorkList: true,
    fourth_editable: true,    // depending on the RBAC
    unApproved: null,
    theVal: 54.3,
    planTabEnadbled: false
  }),

  components: {
    Worklist,
    PrintTitleList,
    Budgets,
    BudgetApprovals,
    Panels
  },

  mounted() {
    // check whether plans are assigned
    //this.setDone('first', 'second')
    this.reloadWorksLists()
    this.toggleWorkList(true)
  },

  methods: {
    toggleWorkList(state) {
      this.showWorkList = state
    },
    reloadWorksLists() {
      const params = {
        is_approved: this.active === 'fourth' ? 1 : 0
      }
      console.log('reloadWorksLists, active, params', this.active, params)

      this.$store.dispatch('LOAD_WORKS_LISTS', params).then(() => {

      })
    },
    setDone(id, index) {
      this[id] = true

      this.unApproved = null

      if (index) {
        this.active = index
      }
    },
    setError() {
      this.unApproved = 'Title list is not approved!'
    },
    itemAssigned(type, itemId) {
      //console.log(`${type} ${itemId == -1 ? ' was un' : ' with the no itemId was '}assigned`)
      this.reloadWorksLists()
      //this.planTabEnadbled = true  //!planId
      //this.setDone('first', 'second')
    },
    showTreatmentsWithAssignedBudget() {
      this.toggleWorkList(true)
    },
    backToStep3() {
      this.toggleWorkList(false)
    }
  },
  computed: {
    savedWorksList() {
      return this.$store.state.TitleList.worksLists
    },
  },
  watch: {
    active(value) {
      console.log('active', value)
      this.toggleWorkList(value === 'first' || value == 'second')
    },
  }
}
</script>

<style lang="scss" scoped></style>
