<!--
File: AssignedBudgetsForm.vue
Description: show list of treatments with the assigned budget.
-->
<template>
  <div class="paddedTable">
    <md-table class="paginated-table table-striped table-hover" :value="tableList" md-fixed-header md-card>
      <md-table-row slot="md-table-row" slot-scope="{item}">
        <md-table-cell :md-label="$t('stdCols.name')">
          {{ item.section_description }}
        </md-table-cell>
        <md-table-cell :md-label="$t('treatment.treatment_description')">
          {{ item.treatment_type_description }}
        </md-table-cell>
        <md-table-cell :md-label="$t('condition.cost')" md-numeric>
          {{ item.cost / showAmounts | numFormat }}
        </md-table-cell>
      </md-table-row>
    </md-table>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <span>
        {{ $t('budget.works_count') }}: {{ summaryInfo.totalRecords | numFormat(0) }}&nbsp;&nbsp;&nbsp;
        {{ $t('budget.total_cost') }}: {{ summaryInfo.totalSum / this.showAmounts | numFormat }}
      </span>
      <md-button class="md-accent" @click.stop.prevent="onClose">
        {{ $t('buttons.close') }}
      </md-button>
    </div>
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import { numFormat } from "@/store/refdata/format_helpers"

  export default {
    name: 'assigned-budgets-form',
    data() {
      return {
        showAmounts: 1000000,
      }
    },

    props: {
      budget: { default: {}, type: Object },
      workList: { default: 0, type: Number }
    },

    components: {
    },

    mounted() {
      this.loadTreatmentsList()
    },
    methods: {
      onClose() {
        this.$emit('close')
      },
      loadTreatmentsList() {
        if (!this.budget || !this.workList) {
          return
        }
        const params = {
          work_list_id: this.workList,
          budget_id: this.budget.budget_id,
        }
        this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
        })
      },
    },

    computed: {
      ...mapState({
        tableList: (state) => state.TitleList.treatmentsList,
      }),
      ...mapGetters(['treatmentsSummary']),
      summaryInfo() {
        return this.$store.getters.treatmentsSummary
      },
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.paddedTable {
  padding: 5px;
  margin: 5px 5px 5px 5px;
}

.md-table-head-label {
  padding: 5px;
}
</style>